// 房源管理 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "#",
        width: 50
    },
    {
        prop: "name",
        label: "地址",
        width: 450
    },
    {
        prop: "tel1",
        label: "性别",
        render: (h, {row}) => {
            return h("span", "-");
        }
    },
    {
        prop: "workInfo",
        label: "承租人",
        render: (h, {row}) => {
            return h("span", "-");
        }
    },
    {
        prop: "outsideArea",
        label: "建筑面积",
        render: (h, {row}) => {
            const {outsideArea} = row;
            return h("span", outsideArea+"㎡");
        }
    },
    {
        prop: "status",
        label: "状态",
        render: (h, {row}) => {
            const {status} = row;
            let statusName = null;
            switch (status) {
                case 0:
                    statusName = "空置";
                    break;
                case 1:
                    statusName = "已绑定";
                    break;
                case 2:
                    statusName = "合同未签";
                    break;
                case 3:
                    statusName = "租赁中";
                    break;
            }
            return h("span", statusName);
        }
    },
    {
        prop: "comment",
        label: "备注",
    },
    {
        prop: "apartmentType",
        label: "户型",
    },
];
