import request from "@http";

// 获取小区列表
export function getCommunityListApi() {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/community/`
    });
}

// 获取房源列表
export function getApartmentListApi(params) {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/chart/report/apartment/all`,
        params
    });
}

// 获取全部房源列表
export function getApartmentAllListApi(params) {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/chart/report/apartment/all/list`,
        params
    });
}

// 批量修改备注
export function batchRemarks(data) {
    return request({
        method: "put",
        url: `/api/gzf/baseinfo/apartment/batch/remarks`,
        data
    });
}

// 导出房源列表
export function getApartmentExportApi(params) {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/chart/report/apartment/all/export`,
        responseType: 'blob',
        params
    });
}