<template>
    <section class="housing-resources-management padding_10_15">
        <div class="form-search-container bg-white">
            <el-form ref="formSearch" :model="formSearch" inline size="small">
                <el-form-item prop="communityUuid">
                    <el-select v-model="formSearch.communityUuid" placeholder="请选择小区" clearable>
                        <el-option v-for="(item,index) in communityList" :key="index" :label="item.name" :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item prop="keyword">
                    <el-input v-model="formSearch.keyword" placeholder="请输入关键字" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                    <el-button type="primary" icon="el-icon-edit-outline" @click="openDialog">批量编辑备注</el-button>
                    <el-button type="primary" icon="el-icon-download" @click="exportXlsx">导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getApartmentListApi" :columns="tableColumn"
                   :height="810">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

        <dialog-batch-remarks ref="dialogBatchRemarks" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import {tableColumn} from "@/views/housing-resources-management/data";
    import {getCommunityListApi,getApartmentListApi,getApartmentExportApi} from "@/api/housing-resources-management"
    import {downloadByData} from "@/utils/hooks/download";
    export default {
        name: "housing-resources-management",
        data() {
            return {
                tableColumn,
                formSearch: {
                    keyword: null,
                    communityUuid: null,
                },
                communityList: [],
                loadingOptions: {
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                }
            };
        },
        components: {
            dialogBatchRemarks: () => import("@/views/housing-resources-management/components/dialog-batch-remarks")
        },
        methods: {
            getApartmentListApi(params) {
                // 获取租户列表
                return getApartmentListApi({...params, ...this.formSearch});
            },
            handleSearch() {
                // 搜索
                this.$refs['tableRef'].getTableData();
            },
            handleReset() {
                // 重置
                this.$refs["formSearch"].resetFields();
                this.handleSearch();
            },
            getCommunityListApi(){
                getCommunityListApi().then(res=>{
                    const {list} = res;
                    this.communityList = list;
                });
            },
            openDialog(){
                this.$refs["dialogBatchRemarks"].openDialog(this.formSearch);
            },
            exportXlsx(){
                let that = this;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                getApartmentExportApi({...this.formSearch}).then(res=>{
                    downloadByData({data: res, filename: "房源列表.xlsx"});
                    loading.close();
                }).finally(() => loading.close());
            }
        },
        created() {
            this.getCommunityListApi();
        }
    }
</script>

<style lang="scss" scoped>
    .housing-resources-management {

        .form-search-container {
            padding: VH(20px) VW(15px) 0;
            margin-bottom: VH(15px);

            /deep/ .el-form {
                .el-form-item .el-input__inner {
                    width: VW(250px);
                }
            }
        }
    }
</style>